import React from "react";
import "./Content.css";

// Import images
import painting1 from "./1.jpeg";
import painting2 from "./2.jpeg";
import painting3 from "./3.jpeg";
import painting4 from "./4.jpeg";

function Content() {
  return (
    <div className="app">
      <header className="header">
        <h1>Hena Jamal Painting Classes</h1>
        <p>Discover the joy of painting with expert guidance!</p>
      </header>
      <section className="content">
        <h2>Our Specializations</h2>
        <p>At Hena Jamal Painting Classes, we offer professional training in:</p>
        <ul>
          <li>Oil Painting</li>
          <li>Artistic Painting</li>
          <li>Acrylic Painting</li>
          <li>Watercolor Painting</li>
        </ul>
        <p>
          We provide <strong>both online and offline classes</strong>, catering to the needs of every aspiring artist. 
        </p>
        <p>
          Whether you're a beginner or an experienced artist, we help bring out
          your creativity and refine your skills.
        </p>
        <p>
          <strong>
            Fill this{" "}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSc1CtvOri582MyuVJggA1l_RQ1aVX35ZgZsr8IMv4umAHnFQA/viewform?usp=sf_link"
              target="_blank"
              rel="noopener noreferrer"
              className="form-link"
            >
              Google Form
            </a>{" "}
            for admission queries.
          </strong>
        </p>
        <div className="gallery">
          <img src={painting1} alt="Oil Painting" />
          <img src={painting2} alt="Artistic Painting" />
          <img src={painting3} alt="Acrylic Painting" />
          <img src={painting4} alt="Watercolor Painting" />
        </div>
      </section>
      <footer className="footer">
        <p>&copy; 2024 Hena Jamal Painting Classes. All rights reserved.</p>
        <p>
          Developed by{" "}
          <a
            href="https://github.com/zaidiqbal88"
            target="_blank"
            rel="noopener noreferrer"
          >
            Zaid Iqbal
          </a>
        </p>
      </footer>
    </div>
  );
}

export default Content;
